import React from 'react'
import './Loaders.css'
function Loaders() {
  return (
    <div className='loader-container'>
    <div class="loader">
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
</div>
</div>

  )
}

export default Loaders